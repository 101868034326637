<template>
  <div
    class="card"
    style="min-width: 100%; border-radius: 0.5px; max-width: fit-content"
  >
    <div class="card-body">
      <h1>Criar encomenda</h1>
      <hr />

      <section>
        <div></div>
      </section>

      <section>
        <h3>Selecione o ficheiro e folha</h3>
        <span
          v-if="!productsList || productsList.length == 0"
          class="col-auto mt-sm-3 mb-sm-3"
        >
          A carregar <i class="fas fa-spinner fa-spin"></i>
        </span>
        <input
          v-if="productsList && productsList.length > 0"
          class=""
          type="file"
          @change="onChange"
        />
        <xlsx-read v-if="file" :file="file">
          <xlsx-sheets>
            <template #default="{ sheets }">
              <select
                class="form-select form-select-sm mt-1 py-2"
                style="width: 150px"
                v-model="selectedSheet"
              >
                <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                  {{ sheet }}
                </option>
              </select>
              <span v-if="file" class="helper-text fs-8"
                >Selecione a folha</span
              >
            </template>
          </xlsx-sheets>
          <xlsx-json :sheet="selectedSheet" @parsed="parsed"></xlsx-json>
        </xlsx-read>
      </section>

      <!--button to download example csv-->
      <section>
        <xlsx-workbook>
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in sheetExample"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <div style="width: 150px">
            <xlsx-download :filename="`Ficheiro_exemplo.xlsx`">
              <button
                class="btn btn-sm mt-1 py-2 btn-download-example"
                style="width: 150px"
                title="Descarregar excel exemplo"
              >
                <span
                  ><i class="fas fa-download text-dark fs-8 mb-1"></i
                  >Exemplo</span
                >
              </button>
            </xlsx-download>
          </div>
        </xlsx-workbook>
      </section>
    </div>

    <!--input for field with detail of order-->
    <div class="card-body pt-1">
      <h5>Observações da encomenda</h5>
      <input
        class="form-control form-control-sm"
        type="text"
        maxlength="66"
        v-model.trim="orderDetail"
        placeholder="Observações"
      />
    </div>

    <div class="card-body pt-1">
      <div class="border">
        <div class="mt-4 ms-2 col-12 row">
          <label
            class="fs-6 fw-bold col-auto"
            style="align-self: center"
            for="product"
            >Escolher Artigo:&nbsp;</label
          >
          <!-- while products not loaded show loading -->
          <label
            v-if="!productsList || productsList.length == 0"
            class="col-auto mt-sm-3 mb-sm-3"
          >
            A carregar <i class="fas fa-spinner fa-spin"></i>
          </label>

          <input
            v-if="productsList && productsList.length > 0"
            @change="onChangeProduct($event)"
            class="form-control form-control-sm"
            style="width: 16rem"
            list="products"
            id="product"
            ref="productRef"
            name="product"
          />

          <datalist id="products">
            <option v-for="(prod, index) in productsList" :key="index">
              {{ prod.refAndDesign }}
            </option>
          </datalist>
          <label
            v-if="false"
            class="fs-6 fw-bold col-auto ms-xxl-2"
            style="align-self: center"
            for="product"
            >Familia:&nbsp;&nbsp;</label
          >
          <!--Select from families not used in this project-->
          <select
            v-if="false"
            class="form-select form-select-sm mt-1"
            style="width: 150px"
            v-model="selectedFamily"
            @change="onChangeFamily($event)"
          >
            <option value="">Todos</option>
            <option v-for="fam in families" :value="fam.ref" :key="fam.ref">
              {{ fam.nome }}
            </option>
          </select>
        </div>

        <hr style="width: 98%; margin: auto; margin-top: 10px" />

        <form class="form">
          <div class="card-body fs-8">
            <div class="form-group row">
              <div
                class="col-12 d-flex my-1"
                v-bind:class="{
                  'col-xl-3': productField.vol,
                  'col-xl-4': !productField.vol,
                }"
              >
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Cód. Artigo:</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputrefe"
                    v-model.trim="productField.refe"
                  />
                </div>
              </div>
              <div
                class="col-12 d-flex my-1"
                v-bind:class="{
                  'col-xl-3': productField.vol,
                  'col-xl-4': !productField.vol,
                }"
              >
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Descrição:</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputDesignation"
                    v-model.trim="productField.design"
                  />
                </div>
              </div>
              <div
                v-if="productField.vol"
                class="col-12 d-flex my-1"
                v-bind:class="{
                  'col-xl-2': productField.vol,
                  'col-xl-3': !productField.vol,
                }"
              >
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Qtd Caixa:</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputVolume"
                    v-model.trim="productField.vol"
                  />
                </div>
              </div>
              <div v-if="false" class="col-12 col-xl-3 d-flex my-1">
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Qtd. embalagem:</label
                >
                <div class="col-7">
                  <input
                    readonly
                    type="text"
                    class="form-control form-control-solid"
                    id="inputPrice"
                    v-model.trim="productField.u_qtdemb"
                  />
                </div>
              </div>

              <div
                class="col-12 col-xl-3 d-flex my-1"
                
              >
                <label for="artigo" class="col col-form-label fs-6 fw-bold"
                  >Quantidade:</label
                >
                <div class="col-7">
                  <input
                    type="number"
                    class="form-control"
                    id="inputQtt"
                    ref="qttRef"
                    min="1"
                    @keyup.enter="goToProductSelection()"
                    @input="onInputQtt"
                    v-model="productField.qtt"
                  />
                </div>
              </div>

              <div class="col-12 col-xl-1 d-flex my-1 justify-content-center">
                <button
                  title="Guardar produto"
                  v-if="editingIndex !== null"
                  class="btn btn-color"
                  @click="cleanProductField"
                >
                  <i class="fas fa-save p-0" style="color: #3d9a01"></i>
                </button>
                <button
                  title="Adicionar produto"
                  v-if="editingIndex === null"
                  class="btn btn-add"
                  @click="addProduct"
                  :disabled="
                    (!productField.design && !productField.refe) ||
                    !productField.qtt
                  "
                >
                  <i class="fas fa-plus text-white p-0"></i>
                </button>
              </div>

            </div>
          </div>
        </form>
        <!-- <div class="d-flex justify-content-end me-2 mb-2">
          <button
            v-if="editingIndex !== null"
            class="btn btn-color"
            @click="cleanProductField"
          >
            Nova Linha
          </button>
          <button
            v-if="editingIndex === null"
            class="btn btn-add"
            @click="addProduct"
            :disabled="
              (!productField.design && !productField.refe) || !productField.qtt
            "
          >
            Adicionar
          </button>
        </div> -->
      </div>
    </div>

    <div
      v-if="list && list.length"
      class="flex-column flex-xxl-row d-flex justify-content-between align-items-start mx-10"
    >
      <span class="fs-6 d-xxl-flex col-xxl-6">
        <label for="inputObraNum" class="col-auto col-form-label fs-6 fw-bold"
          >Nº de requisição de cliente:&nbsp;</label
        >
        <div class="w-100">
          <input
            type="text"
            class="form-control form-control-lg form-control-sm fs-6 px-4"
            id="inputObraNum"
            v-model="formToSend.strNumExterno"
            ref="numExterno"
            maxlength="10"
          />
          <FieldErrorMessage
            class="mt-1"
            :errors="errors"
            fieldName="strNumExterno"
          ></FieldErrorMessage>
        </div>
      </span>

      <span class="fs-6 d-xxl-flex">
        <label
          for="inputObraNum"
          class="col-auto col-form-label fs-6 fw-bold d-flex"
          >Sugerir data de entrega:&nbsp;</label
        >
        <div>
          <input
            type="date"
            class="form-control form-control-ms fs-6"
            v-model="formToSend.dtmDataEntregaSugerida"
            ref="dataSugerida"
            :min="minDate"
            @change="queryForOccurences"
          />
          <FieldErrorMessage
            class="mt-1"
            :errors="errors"
            fieldName="dtmDataEntregaSugerida"
          ></FieldErrorMessage>
        </div>
      </span>
    </div>
    <div>
      <new-order-table
        style="min-width: 100%; max-width: 100px"
        v-if="list && list.length"
        :list="list"
        @editLine="editLine"
        @removeLine="removeLine"
        @resetNewOrder="resetNewOrder"
      />
    </div>

    <!--Button to submit-->
    <div v-if="list && list.length" class="d-flex justify-content-end m-5 mt-0 mx-9">
      <button ref="sendOrder" class="btn btn-color" @click="submit" :disabled="!list.length">
        <span v-if="!loadingSendingOrder"> Enviar encomenda </span>
        <span v-if="loadingSendingOrder">
          A enviar...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2 mb-1"
          ></span>
        </span>
      </button>
    </div>
    <!--End submit buyton-->
  </div>
</template>

<script>
//npm install -save vue3-xlsx
import {
  XlsxRead,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "/node_modules/vue3-xlsx/dist/vue3-xlsx.cjs.prod.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewOrderTable from "@/components/Orders/NewOrderTable.vue";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import FieldErrorMessage from "@/components/global/FieldErrorMessage.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "ImportCsvAndConvertInOrder",
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    FieldErrorMessage,
    NewOrderTable,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
      sheetExample: {},
      collection: [{ a: 1, b: 2 }],
      list: [],
      productField: {},
      editingIndex: null,
      productsList: [],
      formToSend: {},
      errors: [],
      families: [],
      selectedFamily: "",
      orderDetail: "",
      productsNotFound: [],
      loadingSendingOrder: false,
    };
  },
  computed: {
    storedProducts() {
      return store.getters.getProducts.length;
    },
    minDate() {
      const today = new Date().toISOString().substr(0, 10);
      return today;
    },
  },
  watch: {
    storedProducts() {
      this.productsList = store.getters.getProducts;
    },
    orderDetail() {
      this.formToSend.strObs = this.orderDetail;
    },
    formToSend: {
      handler() {
        store.commit("setOrderHeader", {
          strNumExterno: this.formToSend.strNumExterno,
          strObs: this.formToSend.strObs,
        });
      },
      deep: true,
    },
  },
  methods: {
    // Block insert of any characters and 0
    onInputQtt(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, "");
      if (value === "") value = 1;
      input.value = value;
      this.productField.qtt = value;
    },
    /*
     * ----------------DATA MANIPULATION----------------
     */
    onChangeProduct(e) {
      //some products have no refe so clean before search

      //separate refe and designation by -
      let refe = e.target.value.split(" - ")[0];

      this.productField = {};
      this.productField.design = e.target.value;

      if (!refe.trim().length) return;

      let product = this.productsList.find(
        (prod) => prod.ref.trim() === refe.trim()
      );

      if (product) {
        this.productField.refe = product.ref;
        this.productField.design = product.design;
        this.productField.qtt = product.qtt;
        this.productField.u_qtdemb = product.u_qtdemb;
        this.productField.abv = product.strAbreviatura;

        this.productField.vol = product.ftlVolume;
        this.productField.stk = product.tipoStock;
      } else {
        e.target.value = "";
        this.cleanProductField();
        return;
      }
      //if update on line keep existing qtt and save productField
      if (this.editingIndex !== null) {
        this.productField.qtt = this.list[this.editingIndex].qtt;
        this.productField.refe = product.ref;
        this.productField.design = product.design;
        this.productField.u_qtdemb = product.u_qtdemb;
        this.productField.abv = product.strAbreviatura;

        this.productField.vol = product.ftlVolume;
        this.productField.stk = product.tipoStock;

        this.list[this.editingIndex] = this.productField;
      }

      e.target.value = "";

      //set cursor to be on qtt
      this.$nextTick(() => {
        this.$refs.qttRef.value = 1;
        this.productField.qtt = this.$refs.qttRef.value;
        this.$refs.qttRef.focus();
      });
    },

    removeLine(index) {
      this.list.splice(index, 1);
      store.commit("setProductsCart", this.list);
    },

    resetNewOrder() {
      this.list = [];
      this.formToSend = {};
      this.orderDetail = "";
      this.cleanProductField();
      store.commit("setProductsCart", this.list);
    },

    cleanProductField() {
      this.productField = {};
      this.editingIndex = null;
      store.commit("setProductsCart", this.list);
    },

    editLine(index) {
      this.productField = this.list[index];
      this.editingIndex = index;
    },

    addProduct() {
      if (this.productField.qtt < 1) {
        this.productField.qtt = 1;
      } else {
        this.productField.qtt = Math.ceil(this.productField.qtt);
      }

      if (this.editingIndex === null) {
        let targetProd = this.list.find(
          (prod) => prod.refe === this.productField.refe
        );

        if (targetProd) {
          targetProd.qtt += this.productField.qtt;
        } else {
          this.list.push(this.productField);
        }

        this.productField = {};
      } else {
        this.list[this.editingIndex] = this.productField;
        this.productField = {};
        this.editingIndex = null;
      }

      store.commit("setProductsCart", this.list);

      //set cursor to be on product id
      this.$nextTick(() => {
        this.$refs.productRef.focus();
      });
    },

    onChangeFamily() {
      store.dispatch("loadProducts", "&familia=" + this.selectedFamily);
      this.productsList = [];
    },

    // ----------------- VALIDATE AND MAP FILE PRODUCTS ----------------
    parsed(val) {
      let totalProductsImported = 0;

      val.forEach((item) => {
        if (!item.Ref && !item.Abv) return;

        let product = null;

        if (item.Ref) {
          item.Ref = item.Ref.toString().trim();
          product = this.productsList.find(
            (prod) => prod.ref.trim() === item.Ref
          );
        }

        if (!product) {
          if (item.Abv) {
            item.Abv = item.Abv.toString().trim();
            product = this.productsList.find(
              (prod) => prod.strAbreviatura === item.Abv
            );
          }
          if (product) item.Ref = product.ref;
        }

        if (!product) {
          if (!item.Ref && item.Abv) {
            this.productsNotFound.push(item.Abv);
          }
          if (item.Ref && !item.Abv) {
            this.productsNotFound.push(item.Ref);
          }
          return;
        }

        if (item.Qtt) {
          item.Qtt = item.Qtt.toString().trim().replace(/\D/g, "");
        } else {
          item.Qtt = 1;
        }

        if (item.Qtt == "" || item.Qtt < 1) item.Qtt = 1;

        totalProductsImported++;

        let index = this.list.findIndex(
          (prod) => prod.refe.trim() === product.ref.trim()
        );

        if (index != -1) {
          this.list[index].qtt += parseInt(item.Qtt);
        } else {
          this.list.push({
            refe: product.ref,
            abv: product.strAbreviatura,
            design: product.design,
            qtt: parseInt(item.Qtt),
          });
        }
      });

      store.commit("setProductsCart", this.list);

      const msg = this.productsNotFound.join(" ");
      Swal.fire({
        title: "<span>Ficheiro importado com sucesso!</span>",
        icon: "success",
        html:
          val.length < 1
            ? `<span class="text-center"><i class="fas fa-exclamation-circle text-danger"></i> Sem artigos para adicionar!</span><p class="text-center mt-4 fs-6">Verifique se selecionou o ficheiro ou a folha correta e se o formato está de acordo com o exemplo.</p>`
            : this.productsNotFound.length < 1
            ? `<span class="text-center">${totalProductsImported} artigo${
                totalProductsImported == 1 ? "" : "s"
              } foram adicionado${
                totalProductsImported == 1 ? "" : "s"
              }!</span>`
            : `<p class="text-center">${totalProductsImported} artigo${
                totalProductsImported == 1 ? "" : "s"
              } foram adicionado${
                totalProductsImported == 1 ? "" : "s"
              }!</p><p class="text-center"><i class="fas fa-exclamation-circle text-danger"></i> Não ${
                this.productsNotFound.length == 1 ? "foi" : "foram"
              } encontrado${this.productsNotFound.length == 1 ? "" : "s"} ${
                this.productsNotFound.length
              } artigo${
                this.productsNotFound.length == 1 ? "" : "s"
              } com a${
                this.productsNotFound.length == 1 ? "" : "s"
              } seguinte${
                this.productsNotFound.length == 1 ? "" : "s"
              } Cód. Artigo${
                this.productsNotFound.length == 1 ? "" : "s"
              }/abreviatura${
                this.productsNotFound.length == 1 ? "" : "s"
              }:</p><p class="text-center" style="color: red;">${msg}</p>`,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });

      this.file = null;
      this.selectedSheet = null;
      this.sheetName = null;
      this.sheets = [{ name: "SheetOne", data: [{ c: 2 }] }];
      this.collection = [{ a: 1, b: 2 }];
      this.productsNotFound = [];
    },

    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },

    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },

    createExampleXLSX() {
      this.sheetExample = [];
      //create one sheet
      this.sheetExample.push({
        name: "Encomendas",
        data: [
          {
            Ref: "A123",
            Abv: "Abv1",
            //Design: "Descrição do produto 1",
            Qtt: "1",
          },
          {
            Ref: "B456",
            Abv: "",
            //Design: "Descrição do produto 2",
            Qtt: "2",
          },
          {
            Ref: "",
            Abv: "AbvExemplo123",
            //Design: "Descrição do produto 3",
            Qtt: "3",
          },
        ],
      });
    },

    //Not in use
    generateAndDownloadExampleCSV() {
      const rows = [
        ["Ref", "Qtt"],
        ["A123", "1"],
        ["B456", "2"],
        ["C789", "3"],
      ];

      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "example.csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    },

    loadStoredCart() {
      if (store.getters.getOrder && store.getters.getOrder.Mov_Encomenda_Lin) {
        this.list = store.getters.getOrder.Mov_Encomenda_Lin.map((item) => {
          return {
            refe: item.strCodArtigo,
            design: item.strDescricao,
            qtt: item.fltQuantidade,
            abv: item.strAbreviatura,
            stk: item.tipoStock,
          };
        });

        this.formToSend.strNumExterno = store.getters.getOrder.strNumExterno;
        this.formToSend.strObs = store.getters.getOrder.strObs;
        this.formToSend.dtmDataEntregaSugerida =
          store.getters.getOrder.dtmDataEntregaSugerida;
        this.orderDetail = store.getters.getOrder.strObs;
      }
    },

    /*
     * -----------------API STORE----------------------
     */

    submit() {
      if (this.loadingSendingOrder) return;
      //put client num on formToSend
      this.formToSend.intCodEntidade = store.getters.currentAuthUser.numcl;
      //put list as Lines in form
      this.formToSend.Mov_Encomenda_Lin = this.mapLinesToMov_Encomendas_Lin(
        this.list
      );
      this.formToSend.strNumExterno =
        this.formToSend.strNumExterno !== undefined
          ? this.formToSend.strNumExterno.trim()
          : "";

      if (!this.verifyForm()) {
        if (
          this.errors["dtmDataEntregaSugerida"] ||
          this.errors["numExterno"]
        ) {
          this.$refs.dataSugerida.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
            top: -100, // specify your desired offset in pixels
          });
        }
        toast.warn("Campos por preencher", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: "slide",
          theme: "colored",
          type: "error",
        });
        return;
      }

      this.formToSend.strObs = this.orderDetail;

      this.loadingSendingOrder = true;

      ApiService.setHeader();
      ApiService.post("encomendas", this.formToSend)
        .then((response) => {
          Swal.fire({
            title: "Encomenda enviada com sucesso",
            icon: "success",
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          //clear everything
          this.formToSend = {};
          this.list = [];
          this.productField = {};
          this.editingIndex = null;
          this.file = null;
          this.selectedSheet = null;
          store.commit("clearCart", this.list);
          this.loadingSendingOrder = false;
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Erro ao enviar encomenda",
            icon: "error",
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          this.loadingSendingOrder = false;
        });
    },

    /*
     * ------------------API FETCHING------------------
     */

    loadFamilies() {
      ApiService.get("products/families", "?PageSize=99999").then((res) => {
        this.families = this.orderFamilies(res.data.data);
      });
    },

    /*
     * ------------------EMITS------------------
     */

    /*
     * ------------------VALIDATIONS------------------
     */
    verifyForm() {
      this.errors = [];
      let errorsQtt = 0;
      //obra num must be filled
      if (!this.formToSend.strNumExterno) {
        this.errors["strNumExterno"] =
          "Número de encomenda deve ser preenchido";
        errorsQtt++;
      }

      if (!this.formToSend.dtmDataEntregaSugerida) {
        this.errors["dtmDataEntregaSugerida"] =
          "Data entrega deve ser preenchida";
        errorsQtt++;
      }

      //remove all lines that have no (design and refe) or qtt
      this.list = this.list.filter((line) => {
        if (
          !line.design &&
          !line.design.trim().length &&
          !line.refe &&
          !line.refe.trim().length
        ) {
          return false;
        }
        if (!line.qtt) {
          return false;
        }
        return true;
      });

      //this.list = this.list.filter((line) => line.design !== undefined && line.design.trim().length);

      if (!this.list && !this.list.length) {
        Swal.fire({
          title: "Erro",
          icon: "error",
          text: "Não existem linhas para enviar",
          type: "error",
          confirmButtonText: "Ok",
        });
        errorsQtt++;
      }

      //convert every form.ref in string
      this.list.forEach((item) => {
        if (item.refe) {
          item.refe = item.refe.toString();
        }
      });

      return errorsQtt === 0;
    },

    /*
     * -------------------UTILS--------------------
     */

    concatRefAndDesign(products) {
      products.forEach((product) => {
        let strAbreviatura = product.strAbreviatura;
        if (strAbreviatura && strAbreviatura != null)
          strAbreviatura = "[" + strAbreviatura.trim() + "]";
        product.refAndDesign =
          product.ref.trim() +
          (strAbreviatura ? " " + strAbreviatura : "") +
          " - " +
          product.design.trim();
      });
      return products;
    },
    orderFamilies(families) {
      //remove empty ref
      families = families.filter((family) => family.ref.trim().length);

      //order by ref
      families.sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });

      //remove duplicated ref
      families = families.filter((family, index) => {
        if (index === 0) return true;
        if (family.ref === families[index - 1].ref) return false;
        return true;
      });

      return families;
    },

    goToProductSelection() {
      //set cursor to be on product id
      this.addProduct();
      this.$nextTick(() => {
        this.$refs.productRef.focus();
      });
    },

    mapLinesToMov_Encomendas_Lin() {
      return this.list.map((line, index) => {
        return {
          //make intNumLinha incremental
          intNumLinha: index + 1,
          strCodArtigo: line.refe,
          fltQuantidade: line.qtt,
        };
      });
    },

    loadProducts() {
      store.dispatch("loadProducts");
    },

    /*
     * -------------------STYLES-------------------
     */
  },
  async mounted() {
    setCurrentPageBreadcrumbs("Nova Encomenda");

    if (!this.storedProducts || this.storedProducts.length === 0) {
      this.loadProducts();
    } else {
      this.productsList = store.getters.getProducts;
    }

    this.loadStoredCart();

    //this.loadFamilies();
    this.createExampleXLSX();
  },
};
</script>
<style scoped>
.btn-color {
  background-color: #d9eecc;
  color: #3d9a01 !important;
}

.btn-color:hover {
  background-color: #e8f5e0;
}

.btn-add:hover {
  background-color: #ddf3ff;
  color: #0095e8;
}

.btn-add {
  background-color: #0095e8;
  color: white;
}

.btn-download-example {
  margin-right: 20px;
  background-color: #bdb4b6ff;
  color: black;
  width: 10%;
  font-size: 1rem;
}

.btn-download-example:hover {
  background-color: #adadad;
}
</style>
